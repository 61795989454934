import React, { Component } from 'react';
import { Link } from 'gatsby';
import Dropdown from 'react-dropdown'
import axios from 'axios';
import 'react-dropdown/style.css';
import * as styles from './ContactForm.module.scss';
import { LocContactForm, LocOffices, Loc } from '../frontlocdata';
import Dropzone from 'react-dropzone';

const API_PATH = 'https://dev.leximo.pl/api/mailer.php';

class ContactForm extends Component {

  state = {
    fileName: [ LocContactForm["add-file"][Loc] ],
    fname: [''],
    ftel: '',
    fmail: '',
    fin: '',
    fout: '',
    ftype: '',
    fmessage: '',
    file: [''],
    mailSent: false,
    error: null,
    office: null,
    dropdownClass: styles.irrelevant,
    commsClass: '',
    typeClass: '',
    officeClass: ''
  };

  defaultRecipient = "vosdqz@gmail.com";

  officeOptions = [
  {value: 'Poznań', label: LocOffices["poznan"][Loc], recipient: "dominik.zgutka@gmail.com"},
  {value: 'Warszawa', label: LocOffices["warsaw"][Loc], recipient: this.defaultRecipient}
  ];

  onReset()
  {
    this.setState({
      fileName: LocContactForm["add-file"][Loc],
      fname: [''],
      ftel: '',
      fmail: '',
      fin: '',
      fout: '',
      ftype: '',
      fmessage: '',
      file: [''],
      mailSent: false,
      error: null,
      office: null,
      dropdownClass: styles.irrelevant,
      commsClass: '',
      typeClass: '',
      officeClass: ''
    });
  }

  onDrop(acceptedFiles)
  {
    this.getFileStatus(acceptedFiles);
    this.setState({
      fileName: this.getFileStatus(acceptedFiles),
      file: acceptedFiles,
    });
  }

  onTypeChanged(event)
  {
    var documentType = event.target.value;

    this.setState({ 
      ftype: documentType,
      dropdownClass: documentType == "certified-translation" ? "" : styles.irrelevant
       });

    this.validateType(true);
    this.validateOffice(true);
  }

  getFileStatus(acceptedFiles)
  {
    let $count = acceptedFiles.length;
    let $targetStatus = LocContactForm["files-selected"][Loc].replace('%', $count);

    if($count > 1)
    {
      if (Loc == 'pl')
      {
        let $countWord = $count.toString();
        let $tens = parseInt($countWord.at(-2), 10);
        let $units = parseInt($countWord.at(-1), 10);
        if($tens == 1 || $units > 4 || $units == 1)
        {
          $targetStatus += "ów";
        }
        else $targetStatus += "i";
      }
      else
      {
        $targetStatus = $targetStatus.replace('file', 'files');
      }
    }
    return $targetStatus;
  }

  validateComms(soft = false) {
    return this.validate("commsClass", () => {return this.state.fmail != '' || this.state.ftel != '' }, styles.invalid, soft);
  }
  validateType(soft = false) {
    return this.validate("typeClass", () => {return this.state.ftype != ''; }, styles.invalid, soft);
  }

  validateOffice(soft = false) {
    return this.validate("officeClass", () => {return this.state.ftype != "certified-translation" || (this.state.office != null && this.state.office != '') }, styles.invalidSelect, soft);
  }

  validate(field, predicate, failStyle, soft = false) {
    var valid = predicate();
    if(!soft || this.state[field] == failStyle) {
      this.setState({[field]: valid ? '' : failStyle});
    }
    return valid;
  }

  validateForm() {
    var valid = this.validateComms();
    valid &= this.validateType();
    return valid &= this.validateOffice();
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    
    if (!this.validateForm()) {
      return;
    }

    let formData = new FormData();
    formData.append("fname", this.state.fname);
    formData.append("fmail", this.state.fmail);
    formData.append("ftel", this.state.ftel);
    formData.append("fin", this.state.fin);
    formData.append("fout", this.state.fout);
    formData.append("ftype", this.state.ftype);
    
    if(this.state.ftype == "certified-translation")
    {
      formData.append("office", this.state.office);
      formData.append("recipient", this.officeOptions.filter(option => option.value == this.state.office)[0].recipient);
    }
    else
    {
      formData.append("office", "");
      formData.append("recipient", this.defaultRecipient);
    }

    formData.append("fmessage", this.state.fmessage);
    formData.append("fileName", this.state.fileName);

    
    for(let i = 0; i < this.state.file.length; i++)
    {
      formData.append("file[]", this.state.file[i]);  
    }
    
    axios({
      method: 'post',
      url: `${API_PATH}`,
      headers: { 'content-type': 'multipart/form-data' },
      data: formData
    })
    .then(response =>
    {
      this.onReset();
      this.setState({ mailSent: true});
      console.log(response);
    })
    .catch(error => {
      this.setState({ error: error.message });
    });
  };

  render () {
    return (
      <div className={styles.wrapper}>
        <div className={styles.content}>
        {this.state.mailSent ?
        <div>
          <p>{LocContactForm["message-sent"][Loc]}</p> <span onClick={() => this.onReset()} className={styles.sent}>{LocContactForm["message-return"][Loc]}</span>
        </div>
          :
          <form onSubmit={this.handleFormSubmit}>
            <div className={styles.col1}>
              <input type="text" placeholder={LocContactForm["name"][Loc]}
                name="fname"
                value={this.state.fname}
                onChange={e => this.setState({ fname: e.target.value })}
              />
            </div>
            <div className={styles.col2}>
              <div>
                <input
                  type="text"
                  placeholder={LocContactForm["phone"][Loc]}
                  name="ftel"
                  value={this.state.ftel}
                  onChange={e => {this.setState({ ftel: e.target.value }); this.validateComms(true) }}
                  className={this.state.commsClass}
                />
              </div>
              <div>
                {/* <label className={styles.movingLabel}></label> */}
                <input type="text" placeholder="EMAIL"
                  name="fmail"
                  value={this.state.fmail}
                  onChange={e => {this.setState({ fmail: e.target.value }); this.validateComms(true)}}
                  className={this.state.commsClass}
                />
              </div>
            </div>
            <div className={styles.col2}>
              <div>
                {/* <label className={styles.movingLabel}></label> */}
                <input type="text" placeholder={LocContactForm["source-language"][Loc]}
                  name="fin"
                  value={this.state.fin}
                  onChange={e => this.setState({ fin: e.target.value })}
                />
              </div>
              <div>
                {/* <label className={styles.movingLabel}></label> */}
                <input type="text" placeholder={LocContactForm["target-language"][Loc]}
                  name="fout"
                  value={this.state.fout}
                  onChange={e => this.setState({ fout: e.target.value })}
                />
              </div>
            </div>
            <div className={styles.col1} style={{'margin': 0}}>
              <label className={this.state.typeClass}>
                <input type="radio" name="type"
                  value="certified-translation"
                  onChange={e => this.onTypeChanged(e)}
                />
                {LocContactForm["certified-translation"][Loc]}
              </label>
            </div>
            <div className={styles.col1} style={{'margin': 0}}>
              <label className={this.state.typeClass}>
                <input type="radio" name="type"
                  value="regular-translation"
                  onChange={e => this.onTypeChanged(e)}
                />
                {LocContactForm["regular-translation"][Loc]}
              </label>
            </div>
            <div className={styles.col1}>
              <label className={this.state.typeClass}>
                <input type="radio" name="type"
                  value="proofreading"
                  onChange={e => this.onTypeChanged(e)}
                />
                {LocContactForm["proofreading"][Loc]}
              </label>
            </div>
            <Dropdown
              className={"select-office " + this.state.dropdownClass}
              options={this.officeOptions}
              onChange={e => this.setState({office: e.value})}
              placeholder={LocContactForm["select-office"][Loc]}
              controlClassName={this.state.officeClass}
              />
            <div className={styles.col1}>
              <div>
                {/* <label className={styles.movingLabel}></label> */}
                <textarea type="text" placeholder={LocContactForm["message"][Loc]}
                  name="fmessage"
                  value={this.state.fmessage}
                  onChange={e => this.setState({ fmessage: e.target.value })}
                ></textarea>
              </div>
            </div>
            <Dropzone onDrop={acceptedFiles => this.onDrop(acceptedFiles)}>
              {({getRootProps, getInputProps}) => (
            <div className={styles.col1} {...getRootProps()}>
              <input type="file" name="type" id="file" className={styles.inputFile} onChange={ (e) => this.onFileChanged(e.target.files) } {...getInputProps()} multiple />
              <label onClick={e=>e.stopPropagation()} htmlFor="file">{ this.state.fileName }</label>
            </div>
              )}
            </Dropzone>
            <p className={styles.info}>{LocContactForm["disclaimer"][Loc]} <a className={styles.link} href="/policy">{LocContactForm["disclaimer-policy-link"][Loc]}</a>.</p>
            <button type="submit" className={styles.formSubmit}>{LocContactForm["send"][Loc]}</button>
          </form>
        }
        </div>
      </div>
    )
  }
};

export default ContactForm;